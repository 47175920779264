import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { socialMedia } from '@config';

const StyledFooter = styled.footer`
  display: none; /* Hide the footer */
`;

const Footer = () => {
  const [githubInfo, setGitHubInfo] = useState({
    stars: null,
    forks: null,
  });

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    fetch('https://api.github.com/repos/bchiang7/v4')
      .then(response => response.json())
      .then(json => {
        const { stargazers_count, forks_count } = json;
        setGitHubInfo({
          stars: stargazers_count,
          forks: forks_count,
        });
      })
      .catch(e => console.error(e));
  }, []);

  return (
    <StyledFooter>
      {/* Content that you want to export */}
      <a href="https://github.com/bchiang7/v4">
        {githubInfo.stars && githubInfo.forks && (
          <div className="github-stats">
            <span>
              {/* Icon components here */}
              <span>{githubInfo.stars.toLocaleString()}</span>
            </span>
            <span>
              {/* Icon components here */}
              <span>{githubInfo.forks.toLocaleString()}</span>
            </span>
          </div>
        )}
      </a>
    </StyledFooter>
  );
};

Footer.propTypes = {
  githubInfo: PropTypes.object,
};

export default Footer;